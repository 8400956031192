<footer class="footer">
    <div class="container-fluid">
        <div class="row" style="text-align: center">
            <div class="col-sm-12">
                © {{year}} Powered By PSVLAB
            </div>
            <!--<div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Crafted with <i class="mdi mdi-heart text-danger"></i> by <a href="https://themesbrand.com/" target="_blank" class="text-reset">Themesbrand</a>
                </div>
            </div>-->
        </div>
    </div>
</footer>